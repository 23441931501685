import Logo from "../assets/dailydiscoverease_banner.png"
import {useEffect, useState} from "react";
import {getContent} from "../api/api";
import Footer from "./Footer";
import Content from "./Content";
import ArticleMainPage from "./ArticleMainPage";
import TopMenu from "./TopMenu";
import Modal from "./Modal";

const Homepage = () => {

    const [content, setContent] = useState([]);
    const [lastAddedIds, setLastAddedIds] = useState([]);
    const [modal, setModal] = useState(true);


    const slicePost = function (data) {
        for (let i=0; i < Object.keys(data).length; i++){
            const index = Object.keys(data)[i];
            data[index].content = data[index].content.slice(0,150) + "[...]"
        }
        return data
    };

    // const sliceEndFromLastItem = function (data){
    //     let len = Object.keys(data).length;
    //     let lastAddedIds = [];
    //     for( let i=0 ;i < 9; i++ ){
    //         lastAddedIds.push(len-i);
    //     }
    //     return lastAddedIds;
    // }


    useEffect(()=>{
        (async ()=>{
           const data = await getContent();
           const newContent = slicePost(data);
           setContent(newContent);
            // setLastAddedIds(sliceEndFromLastItem(data));
            setLastAddedIds([1,2,3,4,5,6,7,8,9])
        })();
        setTimeout(()=>{
            setModal(false);
        } , 2000);
    },[]);

    return (
        <div>
            <TopMenu back_image={Logo} content={content}/>
            {modal? ( <Modal/> ) :
                (<Content>
                    { Object.keys(content).reverse().map(function (key) {
                        if (lastAddedIds.includes(parseInt(key))){
                            return <ArticleMainPage key={key} id={content[key].id} category={content[key].category} title={content[key].title} content={content[key].content} image={content[key].image}/>
                        }
                    })
                    }
                </Content>)}
            <Footer/>
        </div>
    );
}


export default Homepage