import '../styles/TopMenu.css';
import {Link, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";

const TopMenu = (props) => {
    const [content, setContent] = useState([]);
    const navigate = useNavigate();

    useEffect(()=>{setContent(props.content)},[props.content]);

    function shoeMenu(x) {
        x.target.parentElement.childNodes[1].classList.toggle('show-menu');
    };

    function sendQuery (ev){
        ev.preventDefault();
        document.querySelector(".menu.mobile-menu").click();
        let list = [];
        const query = ev.target[0].value;
        for (let i=1; i <= Object.keys(content).length; i++){
            if (content[i].content.toLowerCase().includes(query.toLowerCase()) || content[i].title.toLowerCase().includes(query.toLowerCase())){
                list.push(i);
            }
        }
        navigate('/queryResult', {state:{querySearch:query, queryIds:list, data: content}});
    }

    return (
        <div className="header-container">
            <div className='top-menu'>
                <div className="menu mobile-menu" onClick={(e) => shoeMenu(e)}>
                    <div></div>
                </div>
                <div className='menu-container'>
                    <div className='menu-tab'>
                        <Link to='/' className='tab-text'>Home</Link>
                    </div>
                    <div className='menu-tab'>
                        <Link to='/categories/finance' className='tab-text' state={{stateParam : content}}>Finance</Link>
                    </div>
                    <div className='menu-tab'>
                        <Link to='/categories/health' className='tab-text' state={{stateParam : content}}>Health</Link>
                    </div>
                    <div className='menu-tab'>
                        <Link to='/categories/education' className='tab-text' state={{stateParam : content}}>Education</Link>
                    </div>
                    <div className='menu-tab'>
                        <Link to='/categories/tech' className='tab-text' state={{stateParam : content}}>Tech</Link>
                    </div>
                    <div className='menu-tab'>
                        <Link to='/categories/Lifestyle' className='tab-text' state={{stateParam : content}}>Lifestyle</Link>
                    </div>
                    <div className='menu-tab'>
                        <Link to='/categories/home-improvement' className='tab-text' state={{stateParam : content}}>Home Improvement</Link>
                    </div>
                    <div className='search-query'>
                        <form onSubmit={sendQuery}>
                            <input
                                placeholder='search...'
                                name="query"
                                required
                            />
                        </form>
                    </div>
                </div>
            </div>
            <img className='header-image' src={props.back_image} alt='null' fetchpriority="high"/>
        </div>

    );}

export default TopMenu
