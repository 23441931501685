import Logo from "../assets/dailydiscoverease_banner.png";
import TopMenu from "./TopMenu";
import Footer from "./Footer";
import '../styles/Footer.css';

const TermsConditions = () => {
    return (
        <div>
            <TopMenu back_image={Logo}/>
            <div className="footer-tab-container">
                <div className='footer-title'> <span>TERMS AND CONDITIONS</span></div>
                <div className='privacy-policy-content'>

                    <p>
                        Welcome to Daily Discover Ease!
                    </p>

                    <p>
                        These terms and conditions outline the rules and regulations for the use of Daily Discover Ease's Website, <br/>
                        located at https://dailydiscoverease.com/.
                    </p>

                    <p>
                        By accessing this website we assume you accept these terms and conditions. Do not continue to<br/>
                        use Daily Discover Ease if you do not agree to take all of the terms and conditions stated on this page.
                    </p>

                    <p>
                        The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer<br/>
                        Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this website<br/>
                        and compliant to the Company’s terms and conditions. "The Company", "Ourselves", "We", "Our"<br/>
                        and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves.<br/>
                        All terms refer to the offer, acceptance and consideration of payment necessary to undertake the<br/>
                        process of our assistance to the Client in the most appropriate manner for the express purpose of<br/>
                        meeting the Client’s needs in respect of provision of the Company’s stated services, in accordance<br/>
                        with and subject to, prevailing law of Netherlands. Any use of the above terminology or other words<br/>
                        in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and<br/>
                        therefore as referring to same.<br/>
                    </p>
                    <p>
                        <b> Cookies </b> <br/>
                        We employ the use of cookies. By accessing Daily Discover Ease, you agreed to use cookies in agreement<br/>
                        with the Daily Discover Ease's Privacy Policy. <br/>
                        <br/>
                        Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are<br/>
                        used by our website to enable the functionality of certain areas to make it easier for people visiting<br/>
                        our website. Some of our affiliate/advertising partners may also use cookies.<br/>
                    </p>
                    <p>
                        <b>License</b><br/>
                        Unless otherwise stated, Daily Discover Ease and/or its licensors own the intellectual property rights for all<br/>
                        material on Daily Discover Ease. All intellectual property rights are reserved. You may access this from<br/>
                        Daily Discover Ease for your own personal use subjected to restrictions set in these terms and conditions.<br/>
                        <br/>
                        You must not:<br/>
                             Republish material from Daily Discover Ease<br/>
                             Sell, rent or sub-license material from Daily Discover Ease<br/>
                             Reproduce, duplicate or copy material from Daily Discover Ease<br/>
                             Redistribute content from Daily Discover Ease<br/>
                    </p>

                    <p>
                        This Agreement shall begin on the date hereof. Our Terms and Conditions were created with the<br/>
                        help of the Free Terms and Conditions Generator.<br/>
                    </p>

                    <p>
                        Parts of this website offer an opportunity for users to post and exchange opinions and information in<br/>
                        certain areas of the website. Daily Discover Ease does not filter, edit, publish or review Comments prior to<br/>
                        their presence on the website. Comments do not reflect the views and opinions of Daily Discover Ease,its<br/>
                        agents and/or affiliates. Comments reflect the views and opinions of the person who post their views<br/>
                        <br/>
                        and opinions. To the extent permitted by applicable laws, Daily Discover Ease shall not be liable for the<br/>
                        Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of<br/>
                        and/or posting of and/or appearance of the Comments on this website.<br/>
                    </p>

                    <p>
                        Daily Discover Ease reserves the right to monitor all Comments and to remove any Comments which can<br/>
                        be considered inappropriate, offensive or causes breach of these Terms and Conditions.<br/>
                    </p>

                    <p>
                        You warrant and represent that: <br/>
                         You are entitled to post the Comments on our website and have all necessary licenses and<br/>
                        consents to do so;<br/>
                         The Comments do not invade any intellectual property right, including without limitation<br/>
                        copyright, patent or trademark of any third party;<br/>
                         The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise<br/>
                        unlawful material which is an invasion of privacy<br/>
                         The Comments will not be used to solicit or promote business or custom or present<br/>
                        commercial activities or unlawful activity.<br/>
                    </p>

                    <p>
                        You hereby grant Daily Discover Ease a non-exclusive license to use, reproduce, edit and authorize others<br/>
                        to use, reproduce and edit any of your Comments in any and all forms, formats or media.<br/>
                    </p>

                    <p>
                        <b>Hyperlinking to our Content</b> <br/>
                        The following organizations may link to our Website without prior written approval:<br/>

                         Government agencies;<br/>
                         Search engines;<br/>
                         News organizations;<br/>
                         Online directory distributors may link to our Website in the same manner as they hyperlink to<br/>
                        the Websites of other listed businesses; and<br/>
                         System wide Accredited Businesses except soliciting non-profit organizations, charity<br/>
                        shopping malls, and charity fundraising groups which may not hyperlink to our Web site.<br/>
                    </p>

                    <p>
                        These organizations may link to our home page, to publications or to other Website information so<br/>
                        long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement<br/>
                        or approval of the linking party and its products and/or services; and (c) fits within the context of the<br/>
                        linking party’s site.<br/>
                    </p>

                    <p>
                        TWe may consider and approve other link requests from the following types of organizations:<br/>
                         commonly-known consumer and/or business information sources;<br/>
                         dot.com community sites;<br/>
                         associations or other groups representing charities;<br/>
                         online directory distributors;<br/>
                         internet portals;<br/>
                         accounting, law and consulting firms; and<br/>
                         educational institutions and trade associations.<br/>
                    </p>

                    <p>
                        We will approve link requests from these organizations if we decide that: (a) the link would not make<br/>
                        us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have<br/>
                        <br/>
                        any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the<br/>
                        absence of Daily Discover Ease; and (d) the link is in the context of general resource information.<br/>
                    </p>

                    <p>
                        These organizations may link to our home page so long as the link: (a) is not in any way deceptive;<br/>
                        (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products<br/>
                        or services; and (c) fits within the context of the linking party’s site.<br/>
                    </p>

                    <p>
                        Approved organizations may hyperlink to our Website as follows:<br/>
                         By use of our corporate name; or<br/>
                         By use of the uniform resource locator being linked to; or<br/>
                         By use of any other description of our Website being linked to that makes sense within the<br/>
                        context and format of content on the linking party’s site.<br/>
                    </p>
                    <p>
                        No use of Daily Discover Ease&#39;s logo or other artwork will be allowed for linking absent a trademark license<br/>
                        agreement.<br/>
                    </p>
                    <p>
                        <b> iFrames </b> <br/>
                        Without prior approval and written permission, you may not create frames around our Webpages<br/>
                        that alter in any way the visual presentation or appearance of our Website.<br/>
                    </p>

                    <p>
                        <b> Content Liability </b> <br/>
                        We shall not be hold responsible for any content that appears on your Website. You agree to protect<br/>
                        and defend us against all claims that is rising on your Website. No link(s) should appear on any<br/>
                        Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise<br/>
                        violates, or advocates the infringement or other violation of, any third party rights.<br/>
                    </p>
                    <p>
                        <b> Your Privacy </b> <br/>
                        Please read Privacy Policy
                    </p>
                    <p>
                        <b> Reservation of Rights</b> <br/>
                        We reserve the right to request that you remove all links or any particular link to our Website. You<br/>
                        approve to immediately remove all links to our Website upon request. We also reserve the right to<br/>
                        amen these terms and conditions and it’s linking policy at any time. By continuously linking to our<br/>
                        Website, you agree to be bound to and follow these linking terms and conditions.<br/>
                    </p>
                    <p>
                        <b>Removal of links from our website</b> <br/>
                        If you find any link on our Website that is offensive for any reason, you are free to contact and inform<br/>
                        us any moment. We will consider requests to remove links but we are not obligated to or so or to<br/>
                        respond to you directly.<br/>
                        We do not ensure that the information on this website is correct, we do not warrant its completeness<br/>
                        or accuracy; nor do we promise to ensure that the website remains available or that the material on<br/>
                        the website is kept up to date.<br/>
                    </p>
                    <p>
                        <b>Disclaimer</b><br/>

                        To the maximum extent permitted by applicable law, we exclude all representations, warranties and<br/>
                        conditions relating to our website and the use of this website. Nothing in this disclaimer will:<br/>
                         limit or exclude our or your liability for death or personal injury;<br/>
                         limit or exclude our or your liability for fraud or fraudulent misrepresentation;<br/>
                         limit any of our or your liabilities in any way that is not permitted under applicable law; or<br/>
                         exclude any of our or your liabilities that may not be excluded under applicable law.<br/>
                        The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are<br/>
                        subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer,<br/>
                        including liabilities arising in contract, in tort and for breach of statutory duty.<br/>
                        As long as the website and the information and services on the website are provided free of charge,<br/>
                        we will not be liable for any loss or damage of any nature.<br/>
                    </p>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default TermsConditions